import vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';

// var config = {
//   apiKey: 'AIzaSyB-iV7gZlB7-Zp4lTh54uLgesx_dI_9fzk',
//   authDomain: 'rentfix-dev.firebaseapp.com',
//   projectId: 'rentfix-dev',
// };

const config = {
  apiKey: 'AIzaSyADu5lTwgNGoa1bekl3dUXDvtAxO9NKsaI',
  authDomain: 'beli-ruma-prod.firebaseapp.com',
  projectId: 'beli-ruma-prod',
};
if (!firebase.apps.length) firebase.initializeApp(config);
const fireGoogleProvider = new firebase.auth.GoogleAuthProvider();
const fireFacebookProvider = new firebase.auth.FacebookAuthProvider();
const fireAuth = firebase.auth();
// export default firebase;

// !firebase.apps.length ? firebase.initializeApp(config) : '';
// export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
// export default firebase

// export default ({ app, store }) => {
//     // Set i18n instance on app
//     // This way we can use it in middleware and pages asyncData/fetch
//     app.fireAuth = firebase.auth();
//     app.fireGoogleProvider = new firebase.auth.GoogleAuthProvider();
//     app.fireFacebookProvider = new firebase.auth.FacebookAuthProvider();
//
// }

const FireAuthPlugin = {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    // // Add or modify global methods or properties.
    // Vue.yourMethod = (value) => value
    // // Add a component or directive to your plugin, so it will be installed globally to your project.
    // Vue.component('component', Component)
    // // Add `Vue.mixin()` to inject options to all components.
    // Vue.mixin({
    //     // Add component lifecycle hooks or properties.
    //     created() {
    //         console.log('Hello from created hook!')
    //     }
    // })
    // Add Vue instance methods by attaching them to Vue.prototype.
    Vue.prototype.$fireAuth = fireAuth;
    Vue.prototype.$fireGoogleProvider = fireGoogleProvider;
    Vue.prototype.$fireFacebookProvider = fireFacebookProvider;
  },
};
vue.use(FireAuthPlugin);
