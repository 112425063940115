import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify, {
  VStepper,
  VStepperContent,
  VStepperStep,
  VApp,
  VBtn,
  VTextField,
  VSelect,
  VMenu,
  VBreadcrumbs,
  VIcon,
  VCard,
  VSimpleTable,
  VTabs,
  VTab,
  VTabsItems,
  VTabItem,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VStepper,
    VStepperContent,
    VBtn,
    VStepperStep,
    VApp,
    VTextField,
    VSelect,
    VMenu,
    VBreadcrumbs,
    VIcon,
    VCard,
    VSimpleTable,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
  },
});

const opts = {
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
};

export default new Vuetify(opts);
