const mapKey =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyAg8OArbG2qQg19UE5m7Zux_RHWaEe70f0'
    : 'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0';

export const headConfig = {
  title: 'BeliRumah.co',
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content:
        'rumah dijual, apartemen dijual, jual rumah, jual apartemen, beli rumah, beli apartemen, BeliRumah.co, BeliRumah, ' +
        'marketplace real estate, real estate indonesia',
    },
    { name: 'robots', content: 'Index, Follow' },
    { name: 'facebook-domain-verification', content: 'kvv6qxu8eklfsd7uzr0yd2aqzs2acf' },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/img/icons/favicon-16x16.png' },
    { rel: 'manifest', href: '/manifest.json' },
    {
      rel: 'stylesheet',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css',
    },
    {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css',
      defer: true,
    },
    { rel: 'dns-prefetch', href: 'https://www.googletagmanager.com' },
    // { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
    { rel: 'preconnect', href: 'https://www.google.com' },
    { rel: 'preconnect', href: 'https://www.google.co.id' },
    { rel: 'preconnect', href: 'https://maps.googleapis.com' },
    { rel: 'preconnect', href: 'https://googleads.g.doubleclick.net' },
    { rel: 'preconnect', href: 'https://api.belirumah.co', crossorigin: true },
    { rel: 'preconnect', href: 'https://stats.pusher.com' },
    { rel: 'preconnect', href: 'https://beliruma.s3.ap-southeast-1.amazonaws.com' },
    {
      rel: 'preconnect',
      href: 'https://beliruma.s3-ap-southeast-1.amazonaws.com',
    },
    // {
    //   rel: 'preload',
    //   href: 'https://www.google-analytics.com/analytics.js',
    //   as: 'script',
    //   type: 'text/javascript',
    // },
    {
      rel: 'preload',
      href: 'https://www.googleadservices.com/pagead/conversion_async.js',
      as: 'script',
      type: 'text/javascript',
    },
    // {
    //   rel: 'preload',
    //   href: 'https://www.googletagmanager.com/gtag/js?id=UA-209160690-1&l=dataLayer',
    //   as: 'script',
    //   type: 'application/javascript',
    // },
    {
      rel: 'preload',
      href: 'https://s3-ap-southeast-1.amazonaws.com/rentfix/fonts/ionicons/ionicons.ttf?v=2.0.0',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/fonts/poppins/Poppins-Regular.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/fonts/poppins/Poppins-Medium.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/fonts/poppins/Poppins-SemiBold.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/fonts/poppins/Poppins-Bold.woff2',
      as: 'font',
      crossorigin: true,
    },
    { rel: 'alternate', href: 'android-app://com.beliruma.app/https/belirumah.co' },
    { rel: 'alternate', href: 'ios-app://1625061665/https/belirumah.co' },
  ],
  script: [
    {
      src: 'https://maps.googleapis.com/maps/api/js?key=' + mapKey + '&libraries=places',
      defer: true,
    },
    {
      src: 'https://www.google.com/recaptcha/api.js?render=explicit',
      async: true,
      defer: true,
    },
  ],
};
