export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  districts: [],
  district: null,
  villages: [],
  village: null,
  bankAccountCheckPayload: null,
  checkBankAccountInbox: false,
  owner: null,
  agentTypeList: [],
  agentType: null,
  propertySpecList: [],
  priceSpecList: [],
  areaSpecList: [],
  agentOfficeList: [],
  selectedPropertySpec: [],
  selectedPriceSpec: [],
  selectedAreaSpec: [],
  agentOffice: null,
  socmedList: [],
  selectedSocmed: [],
  allowEditPhoneNumberAfter: null,
  nib: null,
});

export const getters = {};

export const mutations = {
  SET_BANK_ACC_CHECK_PAYLOAD(state, payload) {
    state.bankAccountCheckPayload = payload;
  },
  SET_CHECK_BANK_ACC_INBOX(state, payload) {
    state.checkBankAccountInbox = payload;
  },
  set_provinces(state, provinces) {
    state.provinces = provinces;
  },
  set_province(state, province) {
    state.province = province;
  },
  set_cities(state, cities) {
    state.cities = cities;
  },
  set_city(state, city) {
    state.city = city;
  },
  set_districts(state, districts) {
    state.districts = districts;
  },
  set_district(state, district) {
    state.district = district;
  },
  set_villages(state, villages) {
    state.villages = villages;
  },
  set_village(state, village) {
    state.village = village;
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
  SET_AGENT_TYPE_LIST(state, payload) {
    state.agentTypeList = payload;
  },
  SET_AGENT_TYPE(state, payload) {
    state.agentType = payload;
  },
  SET_PROPERTY_SPEC_LIST(state, payload) {
    state.propertySpecList = payload;
  },
  SET_PRICE_SPEC_LIST(state, payload) {
    state.priceSpecList = payload;
  },
  SET_AREA_SPEC_LIST(state, payload) {
    state.areaSpecList = payload;
  },
  SET_AGENT_OFFICE_LIST(state, payload) {
    state.agentOfficeList = payload;
  },
  SET_SELECTED_PROPERTY_SPEC(state, payload) {
    state.selectedPropertySpec = payload;
  },
  SET_SELECTED_PRICE_SPEC(state, payload) {
    state.selectedPriceSpec = payload;
  },
  SET_SELECTED_AREA_SPEC(state, payload) {
    state.selectedAreaSpec = payload;
  },
  SET_AGENT_OFFICE(state, payload) {
    state.agentOffice = payload;
  },
  SET_SOCMED_LIST(state, payload) {
    state.socmedList = payload;
  },
  SET_SELECTED_SOCMED(state, payload) {
    state.selectedSocmed = payload;
  },
  SET_ALLOW_EDIT_PHONE_NUMBER_AFTER(state, payload) {
    state.allowEditPhoneNumberAfter = payload;
  },
  SET_NIB(state, payload) {
    state.nib = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async changeAvatar({ commit }, formData) {
    try {
      const response = await this.$http.post('/api/upload-profile', formData);
      if (response.data.error) {
        return response.data;
      } else {
        // commit('global/SET_USER', response.data.data, { root: true });
      }
      return response;
    } catch (e) {
      throw e;
    }
  },
  async changePassword({ commit }, formData) {
    const response = await this.$http.post('/api/change-password', formData);
    if (response.data.error) {
      return response.data;
    } else {
      commit('global/SET_USER', response.data.data, { root: true });
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateProfile({}, formData) {
    return await this.$http.post('/api/agent/update-profile', formData);
  },
  // eslint-disable-next-line no-empty-pattern
  async getProfileForm({ commit }) {
    return await this.$http.get('/api/agent/update-profile').then((response) => {
      let data = response.data.data;

      let baseData = data.base_data;
      commit('SET_AGENT_TYPE_LIST', baseData.agent_types);
      commit('SET_PROPERTY_SPEC_LIST', baseData.property_specializations);
      commit('SET_PRICE_SPEC_LIST', baseData.price_specializations);
      commit('SET_AREA_SPEC_LIST', baseData.cities);
      commit('SET_AGENT_OFFICE_LIST', baseData.agent_offices);
      commit('SET_SOCMED_LIST', baseData.social_medias ? baseData.social_medias : []);
      commit('SET_ALLOW_EDIT_PHONE_NUMBER_AFTER', baseData.allow_edit_phone_number_after);

      let editData = data.edit_data;
      commit('SET_AGENT_TYPE', { id: editData.agent_type_id, name: editData.agent_type });
      commit(
        'SET_SELECTED_PROPERTY_SPEC',
        editData.selected_property_specializations.map((e) =>
          baseData.property_specializations.find((property) => property.id === e),
        ),
      );
      commit(
        'SET_SELECTED_PRICE_SPEC',
        editData.selected_price_specializations.map((e) =>
          baseData.price_specializations.find((price) => price.id === e),
        ),
      );
      commit(
        'SET_SELECTED_AREA_SPEC',
        editData.selected_area_specializations.map((e) =>
          baseData.cities.find((city) => city.id === e),
        ),
      );
      commit(
        'SET_AGENT_OFFICE',
        baseData.agent_offices.find((office) => office.id === editData.agent_office_id),
      );
      if (editData.social_medias) {
        commit('SET_SELECTED_SOCMED', editData.social_medias);
      }
      commit('SET_NIB', editData.nib);
      return response.data.data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async getOwner({ commit }, userHashId) {
    return await this.$http.get('/api/v2/projects/owner/' + userHashId).then((response) => {
      commit('SET_OWNER', response.data.data);
      return response.data.data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async getAgent({ commit }, agentHashId) {
    return await this.$http.get('/api/agent/profile/' + agentHashId).then((response) => {
      commit('SET_OWNER', response.data.data);
      return response.data.data;
    });
  },
  async getBillingInformation() {
    return await this.$http.get('/api/v2/user/billing-information').then((response) => {
      return response.data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async updateUserBillingInformation({}, requestData) {
    let response = await this.$http.put('/api/v2/user/update-user-billing', requestData);

    return response.data;
  },
  async checkUserCanAcceptBooking() {
    const { data } = await this.$http.get('/api/profile/check/can_accept_booking');
    return data.data;
  },
  async checkUserCanAddProject() {
    const { data } = await this.$http.get('/api/profile/check/can_add_project');
    return data.data;
  },
  async checkUserCanAddProjectUserId() {
    const { data } = await this.$http.get('/api/profile/check/can_add_project_user_id');
    return data.data;
  },
};
